import * as React from 'react'
import { Layout } from '../components/layout'
import { Seo } from '../components/common'
import PaperCard from '../components/papers/PaperCard'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const PaperIndex = () => {
  const paper = [
    {
      // imgsrc: '../../images/papers/mm24/framework.png',
      title:
        'Explicit Granularity and Implicit Scale Correspondence Learning for Point-Supervised Video Moment Localization',
      authors: ['Kun Wang', 'Hao Liu', 'Lirong Jie', 'Zixu Li', 'Yupeng Hu', 'Liqiang Nie'],
      conference: 'Proceedings of the 32st ACM International Conference on Multimedia, 2024. (CCF-A)',
    },
    {
      // imgsrc: '../../images/papers/tist23/framework.png',
      title: 'Semantic Collaborative Learning for Cross-Modal Moment Localization',
      authors: ['Yupeng Hu', 'Kun Wang', 'Meng Liu', 'Haoyu Tang', 'Liqiang Nie'],
      conference: 'ACM Transactions on Information Systems. (CCF-A)',
    },
    {
      title: 'Coarse-to-fine semantic alignment for cross-modal moment localization',
      authors: ['Yupeng Hu', 'Liqiang Nie', 'Meng Liu', 'Kun Wang', 'Yinglong Wang', "Xian-Sheng Hua"],
      conference: 'IEEE Transactions on Image Processing. (CCF-A, SCI-Q1)',
    }
  ]
  return (
    <Layout>
      <div className="mt-16">
        <h1 className="text-3xl font-bold mb-8">Selected Papers</h1>
        <h1 className="text-3xl font-bold mb-4">2024</h1>
        {/* ACM MM 2024 */}
        <Link to="/paper/sgsci" className="logo">
          <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl">
            <div className="flex">
              <PaperCard title={paper[0].title} authors={paper[0].authors} conference={paper[0].conference} />
              <div className="flex-shrink-0">
                <StaticImage className="h-full w-64 object-cover" src="../images/papers/mm24/framework.png" />
              </div>
            </div>
          </div>
        </Link>
        <h1 className="text-3xl font-bold mb-4 mt-8">2023</h1>
        {/* ACM TOIS 2023 */}
        <Link to="/paper/seen" className="logo">
          <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl">
            <div className="flex">
              <PaperCard title={paper[1].title} authors={paper[1].authors} conference={paper[1].conference} />
              <div className="flex-shrink-0">
                <StaticImage className="h-full w-64 object-cover" src="../images/papers/tist23/framework.png" />
              </div>
            </div>
          </div>
        </Link>
        <h1 className="text-3xl font-bold mb-4 mt-8">2021</h1>
        <div className="logo">
        <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl">
            <div className="flex">
              <PaperCard title={paper[2].title} authors={paper[2].authors} conference={paper[2].conference} />
              <div className="flex-shrink-0">
                <StaticImage className="h-full w-64 object-cover" src="../images/papers/otherpaper/TIP21.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PaperIndex

export const Head = () => <Seo title="Papers" />
